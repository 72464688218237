<template>
  <div>
    <div v-if="project.service_type_id == 0 && (svc_coating.floor_color?.length
      || svc_coating.floor_materials?.length
      || svc_coating.coating_area
      || (svc_coating.coating_range2_text && svc_coating.coating_range2_text !== '-')
      || (svc_coating.coating_range1_text && svc_coating.coating_range1_text !== '-')
      || floor_genres[svc_coating.floor_genre]
      || removal_works[svc_coating.removal_work]
      || premium_cleaners[svc_coating.premium_cleaner]
      || tough_sealers[svc_coating.tough_sealers]
      || micro_mops[svc_coating.micro_mop]
      || premium_cleaners[svc_coating.premium_cleaner]
      || counter_table[svc_coating.counter_table]
      || svc_coating.assigned_partner
      || svc_coating.partner_workrange
    )" class="info-box mt-5 pa-5">
      <v-row>
        <v-col cols="12" class="info-box-headline text-heading-4">
          <v-icon class="mr-4" color="#4F55A7" size="10">mdi-checkbox-blank-circle</v-icon>作業情報
        </v-col>
        <v-col cols="12" class="flex-grow-1">
          <v-row>
            <template>
              <v-col cols="12" class="info-box-work-information-headline top"
                v-if="svc_coating.coating_genre || svc_coating.coating_area">
                <template v-if="project.svc_coating.coating_genre"> {{ coatingGenres }} </template>
                <span class="ml-4" v-if="svc_coating.coating_area">{{ svc_coating.coating_area }} ㎡</span>
              </v-col>
              <v-col cols="12" class="sub pt-0" v-if="(svc_coating.coating_range2_text && svc_coating.coating_range2_text !== '-')
              || (svc_coating.coating_range1_text && svc_coating.coating_range1_text !== '-')">
                {{
                    svc_coating.coating_genre === 3
                      ? svc_coating.coating_range2_text
                      : svc_coating.coating_range1_text
                }}
              </v-col>
            </template>
            <v-col cols="12">
              <v-row no-gutters>
                <template v-if="svc_coating.floor_color?.length || svc_coating.floor_materials?.length">
                  <v-col cols="5" class="info-box-work-information-content-label mb-2 text-heading-3">床材の色</v-col>
                  <v-col cols="7" class="extra-info text-heading-3 d-flex flex-wrap">
                    <template v-if="svc_coating.floor_materials?.length !== 0">
                      <div :key="`coating-color-${index}`" class="d-flex align-center mb-4" v-for="(floorMaterial,
                      index) in svc_coating.floor_materials">
                        <v-avatar class="mr-4" tile min-height="65" min-width="100"
                          style="border-radius: 5% !important;" :color="
                            floorMaterial.type === 0
                              ? floorMaterial.color_code
                              : ''
                          ">
                          <v-img min-height="65" min-width="30" v-if="floorMaterial.type === 1"
                            :src="floorMaterial.image_url" />
                        </v-avatar>
                        <div class="mr-4">
                          {{ floorMaterial.name }}
                          <div v-if="floorMaterial.pivot.custom_value">
                            ( {{ floorMaterial.pivot.custom_value }} )
                          </div>
                        </div>
                      </div>
                    </template>
                    <span v-else v-for="(floor, index) in svc_coating.floor_color" :key="`coating-color-${index}`"
                      class="d-flex align-center mr-2 pb-1">
                      <span :style="{ backgroundColor: floor.color }" class="dot mr-1"></span>
                      <!-- <v-icon size="20" :color="floor.color"
                      >$dot</v-icon
                    > -->
                      {{ floor.text }}
                      <template v-if="floor.id">({{ floor.text_value }})</template>
                    </span>
                  </v-col>
                </template>
                <template v-if="floor_genres[svc_coating.floor_genre]">
                  <v-col cols="6" class="info-box-work-information-content-label mb-2 text-heading-3">床材種類</v-col>
                  <v-col cols="6" class="extra-info text-heading-3">
                    {{ floor_genres[svc_coating.floor_genre] }} {{ '(' + svc_coating.floor_genre_memo + ')' }}
                  </v-col>
                </template>
                <template v-if="removal_works[svc_coating.removal_work]">
                  <v-col cols="6" class="info-box-work-information-content-label mb-2 text-heading-3">剥離作業</v-col>
                  <v-col cols="6" class="extra-info text-heading-3">
                    {{ removal_works[svc_coating.removal_work] }} <span
                      v-if="svc_coating.removal_work === 0">{{ svc_coating.memo }}</span>
                  </v-col>
                </template>
                <template v-if="micro_mops[svc_coating.micro_mop]">
                  <v-col cols="6" class="info-box-work-information-content-label mb-2 text-heading-3">マイクロモップ</v-col>
                  <v-col cols="6" class="extra-info text-heading-3">
                    {{ micro_mops[svc_coating.micro_mop] }}
                  </v-col>
                </template>
                <template v-if="premium_cleaners[svc_coating.premium_cleaner]">
                  <v-col cols="6" class="info-box-work-information-content-label mb-2 text-heading-3">プレミアム専用クリーナー
                  </v-col>
                  <v-col cols="6" class="extra-info text-heading-3">
                    {{ premium_cleaners[svc_coating.premium_cleaner] }}
                  </v-col>
                </template>
                <template v-if="counter_table[svc_coating.counter_table]">
                  <v-col cols="6" class="info-box-work-information-content-label mb-2 text-heading-3">多目的カウンター</v-col>
                  <v-col cols="6" class="extra-info text-heading-3">
                    {{ counter_table[svc_coating.counter_table] }}
                  </v-col>
                </template>
                <template v-if="tough_sealers[svc_coating.tough_sealer]">
                  <v-col cols="6" class="info-box-work-information-content-label mb-2 text-heading-3">タフシーラー</v-col>
                  <v-col cols="6" class="extra-info text-heading-3">
                    {{ tough_sealers[svc_coating.tough_sealer] }}
                  </v-col>
                </template>
                <template v-if="project?.svc_coating?.assigned_partner">
                  <v-col cols="6" class="info-box-work-information-content-label mb-2 text-heading-3">外部発注</v-col>
                  <v-col cols="6" class="extra-info text-heading-3">
                    {{ project.svc_coating.assigned_partner }}
                  </v-col>
                </template>
                <template v-if="project?.svc_coating?.assigned_partner">
                  <v-col cols="6" class="info-box-work-information-content-label mb-2 text-heading-3">発注範囲</v-col>
                  <v-col cols="6" class="extra-info text-heading-3 white-space-preline break-word-wrap">
                    {{ project.svc_coating.partner_workrange }}
                  </v-col>
                </template>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <v-container v-if="project.service_type_id == 0 && svc_coating.premium_guard?.length" class="info-box mt-5 pa-5">
      <v-row>
        <v-col cols="12" class="info-box-headline text-heading-4">
          <v-icon class="mr-4" color="#4F55A7" size="10">mdi-checkbox-blank-circle</v-icon>作業情報
        </v-col>
        <v-col cols="12" class="flex-grow-1">
          <v-row>
            <v-col cols="12">
              <div class="d-flex justify-space-between">
                <div v-for="item in svc_coating.premium_guard" :key="item.text" class="extra-info text-heading-3">
                  {{ item.text }}
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="project.service_type_id == 0 && svc_coating.campaign" class="info-box mt-5 pa-5">
      <v-row>
        <v-col cols="12" class="info-box-headline text-heading-4">
          <v-icon class="mr-4" color="#4F55A7" size="10">mdi-checkbox-blank-circle</v-icon>キャンペーンサービス
        </v-col>
        <v-col cols="12" class="flex-grow-1">
          <v-row>
            <v-col cols="12">
              <div class="d-flex justify-space-between">
                <div class="extra-info text-heading-3">
                  {{ svc_coating.campaign }}
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="project.service_type_id == 0 && svc_coating.coating_option?.length" class="info-box mt-5 pa-5">
      <v-row>
        <v-col cols="12" class="info-box-headline text-heading-4">
          <v-icon class="mr-4" color="#4F55A7" size="10">mdi-checkbox-blank-circle</v-icon>サービス施工
        </v-col>
        <v-col cols="12" class="flex-grow-1">
          <v-row>
            <v-col cols="12">
              <div class="d-flex justify-start">
                <div v-for="item in svc_coating.coating_option" :key="item.text" class="extra-info text-heading-3 mr-5">
                  {{ item.text }}
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="project.service_type_id == 0 && project.svc_coating.coating_afters?.length"
      class="info-box mt-5 pa-5">
      <v-row>
        <v-col cols="12" class="info-box-headline text-heading-4">
          <v-icon class="mr-4" color="#4F55A7" size="10">mdi-checkbox-blank-circle</v-icon>アフター対応
        </v-col>
        <v-col cols="12" class="flex-grow-1">
          <v-row class="info-box-headline-row" v-for="coating_after in project.svc_coating.coating_afters"
            :key="coating_after.id">
            <v-col cols="12">
              <v-row no-gutters>
                <v-col cols="6" class="text-heading-3">{{
                    formatDate(coating_after.date)
                }}</v-col>
                <v-col cols="6" class="info-box-work-information-content-label text-heading-3 text-right">
                  <span class="label"> 部屋番号：</span>
                  <span class="extra-info text-heading-3">{{ coating_after.room_number }} </span>
                </v-col>
                <v-col cols="12" class="info-box-work-information-content-label text-heading-3 text-right">
                  <span>対応種別：</span>
                  <span class="extra-info text-heading-3"> {{ paidStatus[coating_after.is_paid] }} </span>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3">依頼項目</v-col>
                <v-col cols="9" class="extra-info text-heading-3">
                  {{ showText(coating_after.reason) }}
                </v-col>
              </v-row>
              <v-row no-gutters class="m-top-7">
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3 text-heading-3">
                  <span class="label"> 対応方法 </span>
                </v-col>
                <v-col cols="9" class="extra-info text-heading-3">
                  {{ showText(coating_after.method) }}
                </v-col>
              </v-row>
              <v-row class="m-top-7" no-gutters>
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3">対応結果</v-col>
                <v-col cols="9" class="extra-info text-heading-3">
                  {{ showText(coating_after.after_treatment) }}
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3">対応者</v-col>
                <v-col cols="9" class="extra-info text-heading-3">
                  {{ showUser(coating_after.users) }}
                </v-col>
              </v-row>
              <v-row no-gutters class="m-top-7">
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3">
                  <span class="label"> 対応初見 </span>
                </v-col>

                <v-col cols="9" class="extra-info text-heading-3 white-space-prewrap">
                  {{ coating_after.memo }}
                </v-col>
              </v-row>

            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div v-if="project.svc_coating.coating_afters_count > 10" @click="goToList()"
            class="list-link d-flex justify-center"> [ 一覧をみる ] </div>
        </v-col>
      </v-row>
    </v-container>


    <v-container v-if="project.service_type_id == 1" class="info-box mt-5 pa-5">
      <v-row>
        <v-col cols="12" class="info-box-headline text-heading-4">
          <v-icon class="mr-4" color="#4F55A7" size="10">mdi-checkbox-blank-circle</v-icon>共用部洗浄床洗浄ワックス
        </v-col>
        <v-col cols="12" class="flex-grow-1">
          <v-row dense>
            <v-col cols="12">
              <v-row dense>
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3">清掃前準備</v-col>
                <v-col cols="9" class="extra-info text-heading-3">
                  {{ svc_floor.prep_text }}
                </v-col>
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3">使用洗剤</v-col>
                <v-col cols="9" class="extra-info text-heading-3">
                  {{ svc_floor.detergent_text }}
                </v-col>
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3">洗剤塗布方法</v-col>
                <v-col cols="9" class="extra-info text-heading-3">
                  {{ svc_floor.detergent_method_text }}
                </v-col>
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3">基本洗浄方法</v-col>
                <v-col cols="9" class="extra-info text-heading-3">
                  {{ svc_floor.cleaning_method_text }}
                </v-col>
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3">使用ポリッシャー</v-col>
                <v-col cols="9" class="extra-info text-heading-3">
                  {{ svc_floor.polisher_text }}
                </v-col>
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3">使用自洗機</v-col>
                <v-col cols="9" class="extra-info text-heading-3">
                  {{ svc_floor.cleaning_machine_text }}
                </v-col>
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3">使用パット</v-col>
                <v-col cols="9" class="extra-info text-heading-3 d-flex flex-wrap align-content-center">
                  <div
                      :key="`coating-color-${index}`"
                      class="align-center mb-2"
                      v-for="(pat, index) in svc_floor.pat"
                  >
                    <v-avatar
                        class="mr-4"
                        tile
                        min-height="65"
                        min-width="100"
                        style="border-radius: 5% !important; border: 1px solid #7e7e7e"
                        :color="pat.color"
                    />
                    {{ pat.text }}
                  </div>
<!--                  <span v-for="(pat, index) in svc_floor.pat" cols="auto" :key="`coating-color-${index}`"-->
<!--                    class="d-flex align-center mr-3 pb-1">-->
<!--                    &lt;!&ndash; <v-icon size="20" :color="pat.color"-->
<!--                      >$dot</v-icon-->
<!--                    > &ndash;&gt;-->
<!--                    <span :style="{ backgroundColor: pat.color }" class="dot mr-1"></span>-->
<!--                    {{ pat.text }}-->
<!--                  </span>-->
                </v-col>
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3">場所指定洗浄方法</v-col>
                <v-col cols="9" class="extra-info text-heading-3">
                  {{ svc_floor.certain_place_method_text }}
                </v-col>
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3">指定場所</v-col>
                <v-col cols="9" class="extra-info text-heading-3">
                  {{ svc_floor.certain_place_explanation }}
                </v-col>
                <v-divider></v-divider>
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3">範囲指定</v-col>
                <v-col cols="9" class="extra-info text-heading-3" style="line-height: 2" v-html="svc_floor.waxing_direction_text">
                  <!-- {{ svc_floor.waxing_direction_text }} -->
                </v-col>
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3">使用ワックス</v-col>
                <v-col cols="9" class="extra-info text-heading-3">
                  {{ svc_floor.waxing_product_text }}
                </v-col>
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3">注意事項</v-col>
                <v-col cols="9" class="extra-info text-heading-3">
                  {{ svc_floor.waxing_rule }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="project.service_type_id == 1" class="info-box mt-5 pa-5">
      <v-row>
        <v-col cols="12" class="info-box-headline text-heading-4">
          <v-icon class="mr-4" color="#4F55A7" size="10">mdi-checkbox-blank-circle</v-icon>窓ガラス清掃
        </v-col>
        <v-col cols="12" class="flex-grow-1">
          <v-row>
            <v-col cols="12">
              <v-row dense>
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3">使用脚立</v-col>
                <v-col cols="9" class="extra-info text-heading-3">
                  {{ svc_window.stepladder_text }}
                </v-col>
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3">延長ポール</v-col>
                <v-col cols="9" class="extra-info text-heading-3">
                  {{ svc_window.extension_pole_text }}
                </v-col>
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3">ヘルメット</v-col>
                <v-col cols="9" class="extra-info text-heading-3">
                  {{ svc_window.safety_helmet_text }}
                </v-col>
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3">安全帯</v-col>
                <v-col cols="9" class="extra-info text-heading-3">
                  {{ svc_window.safety_belt_text }}
                </v-col>
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3">外面</v-col>
                <v-col cols="9" class="extra-info text-heading-3">
                  {{ svc_window.outside_text }}
                </v-col>
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3">内面</v-col>
                <v-col cols="9" class="extra-info text-heading-3">
                  {{ svc_window.inside_text }}
                </v-col>
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3">場所指定特記事項</v-col>
                <v-col cols="9" class="extra-info text-heading-3">
                  {{ svc_window.certain_window_method_text }}
                </v-col>
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3">指定場所</v-col>
                <v-col cols="9" class="extra-info text-heading-3">
                  {{ svc_window.certain_window_explanation }}
                </v-col>
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3">注意事項</v-col>
                <v-col cols="9" class="extra-info text-heading-3">
                  {{ svc_window.certain_window_rule }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="project.service_type_id == 1" class="info-box mt-5 pa-5">
      <v-row>
        <v-col cols="12" class="info-box-headline text-heading-4">
          <v-icon class="mr-4" color="#4F55A7" size="10">mdi-checkbox-blank-circle</v-icon>カーペット洗浄
        </v-col>
        <v-col cols="12" class="flex-grow-1">
          <v-row>
            <v-col cols="12">
              <v-row dense>
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3">作業前工程</v-col>
                <v-col cols="9" class="extra-info text-heading-3">
                  {{ svc_carpet.prep_text }}
                </v-col>
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3">前処理作業</v-col>
                <v-col cols="9" class="extra-info text-heading-3">
                  {{ svc_carpet.pre_task_text }}
                </v-col>
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3">洗浄作業</v-col>
                <v-col cols="9" class="extra-info text-heading-3">
                  {{ svc_carpet.washing_text }}
                </v-col>
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3">使用バルチャー</v-col>
                <v-col cols="9" class="extra-info text-heading-3">
                  {{ svc_carpet.machine_text }}
                </v-col>
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3">染み抜き</v-col>
                <v-col cols="9" class="extra-info text-heading-3">
                  {{ svc_carpet.stain_removal_text }}
                </v-col>
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3">汚水回収</v-col>
                <v-col cols="9" class="extra-info text-heading-3">
                  {{ svc_carpet.wastewater_text }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="project.service_type_id > 1" class="info-box mt-5 pa-5">
      <v-row>
        <v-col cols="12" class="info-box-headline text-heading-4">
          <v-icon class="mr-4" color="#4F55A7" size="10">mdi-checkbox-blank-circle</v-icon>作業情報
        </v-col>
        <v-col cols="12" class="flex-grow-1">
          <v-row>
            <v-col cols="3" class="info-box-work-information-content-label text-heading-3">作業内容1</v-col>
            <v-col cols="9" class="extra-info text-heading-3 text-pre-wrap">
              {{ svc_other.task_description_1 }}
            </v-col>
            <v-col cols="3" class="info-box-work-information-content-label text-heading-3">作業内容2</v-col>
            <v-col cols="9" class="extra-info text-heading-3 text-pre-wrap">
              {{ svc_other.task_description_2 }}
            </v-col>
            <v-col cols="3" class="info-box-work-information-content-label text-heading-3">作業内容3</v-col>
            <v-col cols="9" class="extra-info text-heading-3 text-pre-wrap">
              {{ svc_other.task_description_3 }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import dayjs from "@/plugins/dayjs";
export default {
  name: "BasicContent",
  props: ["customer", "data", "project"],
  computed: {
    svc_coating() {
      if (!this.project.svc_coating) return null;
      const coating = this.project.svc_coating;
      coating.coating_range1_text = this.showText(coating.coating_range1);
      coating.coating_range2_text = this.showText(coating.coating_range2);
      coating.floor_color_text = this.showText(coating.floor_color);

      return coating;
    },
    svc_floor() {
      if (!this.project.svc_floor) return null;
      const floor = this.project.svc_floor;
      floor.prep_text = this.showText(floor.prep);
      floor.detergent_text = this.showText(floor.detergent);
      floor.detergent_method_text = this.showText(floor.detergent_method);
      floor.cleaning_method_text = this.showText(floor.cleaning_method);
      floor.polisher_text = this.showText(floor.polisher);
      floor.cleaning_machine_text = this.showText(floor.cleaning_machine);
      floor.pat_text = this.showText(floor.pat);
      floor.certain_place_method_text = this.showText(
        floor.certain_place_method
      );
      // floor.waxing_direction_text = this.showText(
      //   Object.values(floor.waxing_direction)
      // );
      // floor.waxing_place_text = this.showText(
      //   Object.values(floor.waxing_place)
      // );
      floor.waxing_product_text = this.showText(floor.waxing_product);
      // floor.waxing_direction_text = ' '
      floor.waxing_direction_text = '' //this.showText(Object.values(floor.waxing_place))

      if((floor.waxing_direction && floor.waxing_direction?.waxing_place_all_direction) || (floor.waxing_place && floor.waxing_place?.waxing_place_all)) {
        floor.waxing_direction_text += '全体：'
        if(floor.waxing_direction && floor.waxing_direction?.waxing_place_all_direction) {
          floor.waxing_direction_text += floor.waxing_direction?.waxing_place_all_direction?.text
        }
        if(floor.waxing_place && floor.waxing_place?.waxing_place_all) {
          if(floor.waxing_direction && floor.waxing_direction?.waxing_place_all_direction && floor.waxing_place.waxing_place_all) {
            floor.waxing_direction_text += ' (' + floor.waxing_place?.waxing_place_all + ")"
          } else {
            floor.waxing_direction_text += floor.waxing_place?.waxing_place_all
          }
        }
      }

      if((floor.waxing_direction && floor.waxing_direction?.waxing_place_part_1_direction) || (floor.waxing_place && floor.waxing_place?.waxing_place_part_1)) {
        floor.waxing_direction_text += '</br>'
        floor.waxing_direction_text += '部分1: '
        if(floor.waxing_direction && floor.waxing_direction?.waxing_place_part_1_direction) {
          floor.waxing_direction_text += floor.waxing_direction?.waxing_place_part_1_direction?.text
        }
        if(floor.waxing_place && floor.waxing_place?.waxing_place_part_1) {
          if(floor.waxing_direction && floor.waxing_direction?.waxing_place_part_2_direction && floor.waxing_place.waxing_place_part_1) {
            floor.waxing_direction_text += ' (' + floor.waxing_place?.waxing_place_part_1 + ")"
          } else {
            floor.waxing_direction_text +=floor.waxing_place?.waxing_place_part_1
          }
        }
      }

      if((floor.waxing_direction && floor.waxing_direction?.waxing_place_part_2_direction) || (floor.waxing_place && floor.waxing_place?.waxing_place_part_2)) {
        floor.waxing_direction_text += '</br>'
        floor.waxing_direction_text += '部分2: '

        if(floor.waxing_direction && floor.waxing_direction?.waxing_place_part_2_direction) {
          floor.waxing_direction_text += floor.waxing_direction?.waxing_place_part_2_direction?.text
        }
        if(floor.waxing_place && floor.waxing_place?.waxing_place_part_2) {
          if(floor.waxing_direction && floor.waxing_direction?.waxing_place_part_2_direction && floor.waxing_place.waxing_place_part_2) {
            floor.waxing_direction_text += ' (' + floor.waxing_place?.waxing_place_part_2 + ")"
          } else {
            floor.waxing_direction_text += floor.waxing_place?.waxing_place_part_2
          }
        }
      }

      if((floor.waxing_direction && floor.waxing_direction?.waxing_place_part_3_direction) || (floor.waxing_place && floor.waxing_place?.waxing_place_part_3)) {
        floor.waxing_direction_text += '</br>'
        floor.waxing_direction_text += '部分3: '

        if(floor.waxing_direction && floor.waxing_direction?.waxing_place_part_3_direction) {
          floor.waxing_direction_text += floor.waxing_direction?.waxing_place_part_3_direction?.text
        }
        if(floor.waxing_place && floor.waxing_place?.waxing_place_part_3) {
          if(floor.waxing_direction && floor.waxing_direction?.waxing_place_part_3_direction && floor.waxing_place.waxing_place_part_3) {
            floor.waxing_direction_text += ' (' + floor.waxing_place?.waxing_place_part_3 + ")"
          } else {
            floor.waxing_direction_text += floor.waxing_place?.waxing_place_part_3
          }
        }
      }

      if((floor.waxing_direction && floor.waxing_direction?.waxing_place_part_4_direction) || (floor.waxing_place && floor.waxing_place?.waxing_place_part_4)) {
        floor.waxing_direction_text += '</br>'
        floor.waxing_direction_text += '部分4: '

        if(floor.waxing_direction && floor.waxing_direction?.waxing_place_part_4_direction) {
          floor.waxing_direction_text += floor.waxing_direction?.waxing_place_part_4_direction?.text
        }
        if(floor.waxing_place && floor.waxing_place?.waxing_place_part_4) {
          if(floor.waxing_direction && floor.waxing_direction?.waxing_place_part_4_direction && floor.waxing_place.waxing_place_part_4) {
            floor.waxing_direction_text += ' (' + floor.waxing_place?.waxing_place_part_4 + ")"
          } else {
            floor.waxing_direction_text += floor.waxing_place?.waxing_place_part_4
          }
        }
      }

      return floor;
    },
    svc_window() {
      if (!this.project.svc_window) return null;
      const window = this.project.svc_window;
      window.stepladder_text = this.showText(window.stepladder);
      window.extension_pole_text = this.showText(window.extension_pole);
      window.safety_helmet_text = this.showText(window.safety_helmet);
      window.safety_belt_text = this.showText(window.safety_belt);
      window.outside_text = this.showText(window.outside);
      window.inside_text = this.showText(window.inside);
      window.certain_window_method_text = this.showText(
        window.certain_window_method
      );

      return window;
    },
    svc_carpet() {
      if (!this.project.svc_carpet) return null;
      const carpet = this.project.svc_carpet;
      carpet.prep_text = this.showText(carpet.prep);
      carpet.pre_task_text = this.showText(carpet.pre_task);
      carpet.washing_text = this.showText(carpet.washing);
      carpet.machine_text = this.showText(carpet.machine);
      carpet.stain_removal_text = this.showText(carpet.stain_removal);
      carpet.wastewater_text = this.showText(carpet.wastewater);

      return carpet;
    },
    svc_other() {
      if (!this.project.svc_other) return null;
      const other = this.project.svc_other;

      return other;
    },
    siteId() {
      return parseInt(this.$route.params.site_id);
    },
    coatingGenres() {
      if (typeof this.project.svc_coating.coating_genre == 'number') {
        return this.coating_genres[this.project.svc_coating.coating_genre]
      }
      let coatings = '';
      coatings = this.project.svc_coating.coating_genre.map((coating) => this.coating_genres[coating]).join(',')
      return coatings
    }
  },
  data() {
    return {
      paidStatus: {
        0: "無償",
        1: "有償"
      },
      coating_genres: [
        "プレミアムコートEX",
        "プレミアムコートEXプラス",
        "プレミアムコートUVプラス",
        "シリコンコーティング",
        "水性コーティング",
        "プレミアムガード"
      ],
      floor_genres: [
        "3溝突板",
        "2溝突板",
        "1溝タイプ（フラット）",
        "1溝タイプ（ハピアタイプ）",
        "1溝タイプ（木目強調タイプ）",
        "化学床"
      ],
      removal_works: ["あり", "なし"],
      micro_mops: ["あり", "なし"],
      premium_cleaners: ["あり", "なし"],
      counter_table: ["あり", "なし"],
      tough_sealers: ["30%", "100%", "150%", "なし"]
    };
  },
  methods: {
    showText(array) {
      if (!array?.length) return "-";
      return array
        .map(
          item =>
            `${item.text || item.name} ${item.extra_type === "with_text"
              ? ` (${item.text_value || item.value})`
              : ""
            }`
        )
        .join(", ");
    },
    showUser(array) {
      if (!array?.length) return "-";
      return array
        .map(user => `${user.last_name || ""} ${user.first_name || ""}`)
        .join(", ");
    },
    formatDate(value) {
      if (!value) return "-";
      return dayjs(value).format("YYYY年M月D日");
    },
    goToList() {
      this.$router.replace({
        name: 'customer-after-service',
        params: {
          customer_id: this.customer.id,
          site_id: this.siteId
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.info-box {
  background: #f8f9ff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  &-headline {
    color: #4f55a7;
    font-size: 20px;
    font-weight: 700;

    &-row:not(:last-child) {
      border-bottom: 1px solid #e0e0e0;
    }
  }

  &-dot {
    background-color: #4f55a7;
    height: 3px;
    width: 3px;
    border-radius: 50%;
    display: inline-block;
  }

  &-work-information {
    &-headline {
      color: #333333;

      &.top {
        font-size: 18px;
        font-weight: 700;
      }
    }

    &-content {
      &-label {
        color: #828282;
        font-size: 16px;
      }
    }
  }
}

.sub {
  font-size: 0.93rem;
}

.extra-info {
  color: #333333;
  line-height: 1.5;
}

.dot {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1px solid #7e7e7e;
  display: inline-block;
}

.list-link {
  margin-top: 20px;
  cursor: pointer;
}

.white-space-prewrap {
  white-space: pre;
  line-height: 20px;
}
.break-word-wrap {
    word-break: break-word;
  }

.m-top-7 {
  margin-top: 7px;
}

.white-space-preline {
  white-space: pre-line;
}
</style>
