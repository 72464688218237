<template>
  <v-container class="claim-box mt-4 pa-5">
    <v-row>
      <v-col cols="12" class="claim-box-headline">
        <v-row align="center">
          <v-col cols="auto">
            <v-icon class="mr-4" color="#C62E2E" size="10"
              >mdi-checkbox-blank-circle</v-icon
            >
            クレーム
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="flex-grow-1">
        <v-row v-for="item in sortedClaims" :key="item.id" align="center">
          <v-col cols="12" class="claim-box-date text-heading-3">
            {{ item.date || '-' }}
            <v-chip color="#8C5F5F" text-color="white" label small class="ml-3"
              >category</v-chip
            >
          </v-col>
          <v-col cols="12" class="claim-box-title py-0 text-heading-3">
            クレーム内容
          </v-col>
          <v-col cols="12" class="claim-box-content pt-0 text-heading-3">
            {{ item.description }}
          </v-col>
          <v-col cols="12" class="claim-box-title py-0 text-heading-3">
            対応内容
          </v-col>
          <v-col cols="12" class="claim-box-content pt-0 text-heading-3">
            {{ item.response }}
          </v-col>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <div @click="goToDetail()" class="d-flex justify-center">
          [ クレーム一覧 ]
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dayjs from "@/plugins/dayjs";
export default {
  name: "BasicClaim",
  props: ["customer", "sites", "claims"],
  methods: {
    goToDetail() {
      this.$router.push({name: 'customer-claim' , params: {
        customer_id: this.$route.params.customer_id,
        site_id: this.$route.params.site_id
      }})
    }
  },
    computed: {
    sortedClaims() {
      return this.claims.filter((a) => a).sort((a,b) => {
        let dateA = dayjs(a.date)
        let dateB = dayjs(b.date)
        return dateA.isBefore(dateB) ? 1 : -1
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.claim-box {
  background: #ffeeee;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  &-headline {
    font-size: 18px;
    color: #c62e2e;
  }

  font-size: 16px;

  &-date {
    color: #878787;
  }

  &-title {
    color: #878787;
  }

  &-content {
    color: #333333;
    word-break: break-all;
  }
}
</style>
