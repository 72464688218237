<template>
  <v-row>
    <v-col cols="12">
      <BasicInformation
        :projectId.sync="_projectId"
        :customer="customer"
        :project="project"
        @update:site="updateSite"
      />
    </v-col>
    <v-col cols="12">
      <BasicContent :customer="customer" :project="project" />
    </v-col>
    <v-col cols="12" v-if="claims.length && project.service_type_id !== 0">
      <BasicClaim :customer="customer" :claims="claims" />
    </v-col>
    <v-col cols="12" v-if="photos.length">
      <BasicPhoto :customer="customer" :photos="photos" />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex"
import BasicInformation from "@/components/layout/customer/basic/BasicInformation.vue";
import BasicContent from "@/components/layout/customer/basic/BasicContent.vue";
import BasicClaim from "@/components/layout/customer/basic/BasicClaim.vue";
import BasicPhoto from "@/components/layout/customer/basic/BasicPhoto.vue";
export default {
  name: "Basic",
  components: { BasicInformation, BasicContent, BasicClaim, BasicPhoto },
  props: ["customer_id", "site_id", "project_id", "project"],
  computed: {
    ...mapGetters(['photos']),
    _projectId: {
      get() {
        return this.project_id;
      },
      set(newValue) {
        return this.$emit("update:project_id", newValue);
      }
    },
    sites() {
      return this.customer.sites;
    },
    selectedSite() {
      return this.customer.sites.find(site => site.id == this.site_id);
    },
    customer() {
      return this.$store.getters.getCustomer;
    },
    claims() {
      return this.$store.getters.allCustomerClaims;
    }
  },
  methods: {
    async updateSite(value = 0) {
      this.site_key = value
      let site_id = this.site_id
      let param = {
        photo: {
          id: this.customer_id,
          site_id: site_id,
        },
        task: {
          customer_id: this.customer_id,
          site_id: site_id,
        },
        claim: {
          customer_id: this.customer_id,
          site_id: site_id,
        }
      }
      await this.$store.dispatch('PHOTO_GET_ALL_BY_CUSTOMER_ID', param.photo)
      await this.$store.dispatch('CUSTOMER_CLAIM_GET_BY', param.claim)
    }
  },
  data() {
    return {
      service_key: 0,
      site_key: 0,
    };
  }
};
</script>
