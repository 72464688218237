<template>
  <div class="customer-box pa-5">
    <v-row>
      <v-col cols="12">
        <div class="customer-name-top">
          顧客情報
        </div>
        <div class="customer-name-bottom">
          <small>{{ customer.furigana_name }}</small>
          {{ customer.name }}
        </div>
      </v-col>
    </v-row>

    <v-row class="mt-4" no-gutters>
      <template v-if="customerAddress">
        <v-col cols="3" class="customer-box-detail-headline mb-2 text-heading-2">住所</v-col>
        <v-col cols="9" class="customer-box-detail-content text-heading-3">
          <span @click="goToAddress(customerAddress)">{{ customerAddress }}</span>
        </v-col>
      </template>
      <template v-if="customer.phone1">
        <v-col cols="3" class="customer-box-detail-headline mb-2 text-heading-2">電話
        </v-col>
        <v-col cols="9" class="customer-box-detail-content text-heading-3">
          {{ customer.phone1 }}
        </v-col>
      </template>
      <v-col v-if="customer.memo" cols="3" class="customer-box-detail-headline mb-2 text-heading-2">メモ</v-col>
      <v-col v-if="customer.memo" cols="9" class="customer-box-detail-content text-heading-3 white-space-pre">
        {{ customer.memo }}
      </v-col>
    </v-row>

    <v-divider class="my-5"></v-divider>

    <v-row dense>
      <v-col cols="12">
        <div class="customer-name-top">
          物件/現場の基本情報
        </div>
      </v-col>
    </v-row>
    <v-row class="mb-4 justify-center">
      <v-col cols="12">
        <v-select v-model="siteId" :items="sites" class="customer-box-form-input" hide-details
          placeholder="顧客名 / 物件名を入力してください" outlined dense color="#4F55A7" item-text="name" item-value="id"></v-select>
      </v-col>
      <v-icon class="text-center" color="rgba(79, 85, 167, 0.5)">mdi-menu-down</v-icon>
      <v-col cols="12">
        <v-select v-model="_projectId" :items="services" class="customer-box-form-input" hide-details
          placeholder="Related service type" outlined dense color="#4F55A7"></v-select>
      </v-col>
    </v-row>

    <v-row class="mt-4" no-gutters>
      <v-col cols="3" class="customer-box-detail-headline mb-2 text-heading-2">物件名</v-col>
      <v-col cols="9" class="customer-box-detail-content text-heading-3">
        {{ selectedSite.name || "-" }}
      </v-col>
      <v-col cols="3" class="customer-box-detail-headline mb-2 text-heading-2">物件名（かな）</v-col>
      <v-col cols="9" class="customer-box-detail-content text-heading-3">
        {{ selectedSite.furigana_name || "-" }}
      </v-col>
      <v-col cols="3" class="customer-box-detail-headline mb-2 text-heading-2">所在地</v-col>
      <v-col cols="9" class="customer-box-detail-content text-heading-3">
        <span @click="goToAddress(siteAddress)">{{ siteAddress }}</span>
      </v-col>
      <v-col cols="3" class="customer-box-detail-headline mb-2 text-heading-2">サービス
      </v-col>
      <v-col cols="9" class="customer-box-detail-content text-heading-3">
        {{
        serviceTypeName || "-"
        }}
      </v-col>
      <v-col v-if="selectedSite.file_pdf" cols="3" class="customer-box-detail-headline mb-2 text-heading-2">PDF
      </v-col>
      <v-col v-if="selectedSite.file_pdf" cols="9" class="customer-box-detail-content text-heading-3">
        <a :href="selectedSite.file_pdf_url" target="_blank" class="mr-2 blue--text text-decoration-underline">{{
        selectedSite.file_pdf_name }}</a>
      </v-col>
    </v-row>

    <v-divider class="my-5"></v-divider>

    <v-row class="mt-4" no-gutters>
      <template v-if="project.partner_0_name || project.partner_0?.name">
        <v-col cols="3" class="customer-box-detail-headline mb-2 text-heading-2">メーカー</v-col>
        <v-col cols="9" class="customer-box-detail-content text-heading-3">
          {{ project.partner_0_name || project.partner_0?.name }}
        </v-col>
      </template>
      <template v-if="project?.partner_2_name || project.partner_2?.name">
        <v-col cols="3" class="customer-box-detail-headline mb-2 text-heading-2">発注会社
        </v-col>
        <v-col cols="9" class="customer-box-detail-content text-heading-3">
          {{ project?.partner_2_name || project.partner_2?.name }}
        </v-col>
      </template>
      <template v-if="project?.on_site_monitoring !== null">
        <v-col cols="3" class="customer-box-detail-headline mb-2 text-heading-2">現場立合</v-col>
        <v-col cols="9" class="customer-box-detail-content text-heading-3">
          {{ on_site_monitoring[project.on_site_monitoring] || "-" }}
        </v-col>
      </template>
      <template v-if="project?.partner_1_name || project.partner_1?.name">
        <v-col cols="3" class="customer-box-detail-headline mb-2 text-heading-2">元請会社</v-col>
        <v-col cols="9" class="customer-box-detail-content text-heading-3">
          {{ project?.partner_1_name || project.partner_1?.name }}
        </v-col>
      </template>
    </v-row>

    <v-divider
      v-if="project.partner_0_name || project.partner_2_name || project.on_site_monitoring !== null || project.partner_1_name"
      class="my-5"></v-divider>

    <v-row class="mt-4" no-gutters>
      <template v-for="info in itemInfos">
        <template v-if="project[info.keys] !== null">
          <v-col :key="info.title" cols="3" class="customer-box-detail-headline text-heading-2 mb-2">{{ info.title }}
          </v-col>
          <v-col :key="info.keys" cols="9" class="customer-box-detail-content text-heading-3">
            <template v-if="info.title == '鍵'">
              {{ showText(project[info.keys]) }}
            </template>
            <template v-if="info.title != '鍵'">
              {{ detailInfo[info.keys][project[info.keys]] || "-" }}
            </template>
            <template v-if="info.keys === 'parking'">
              {{
              project.parking_text && project.parking == 0
              ? '(' + project.parking_text + ')'
              : ''
              }}
            </template>
          </v-col>
        </template>
      </template>
    </v-row>
    <!--    <v-divider v-if="showItemInfos" class="my-5"></v-divider>-->
    <template v-if="project.general_guideline">
      <v-row class="mt-4" no-gutters>
        <v-col cols="3" class="customer-box-detail-headline text-heading-2 mb-2">
          現場に関するメモ
        </v-col>
        <v-col cols="9" class="customer-box-detail-content general-guideline text-pre-wrap text-heading-3">
          {{ project?.general_guideline || "-" }}
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
export default {
  name: "BasicInformation",
  props: {
    projectId: {
      type: [Number, String],
      default: null,
      required: false
    },
    customer: {
      type: Object,
      default: null,
      required: true
    },
    project: {
      type: Object,
      default: null,
      required: true
    }
  },
  computed: {
    _projectId: {
      get() {
        return this.projectId;
      },
      set(newValue) {
        return this.$emit("update:projectId", newValue);
      }
    },
    siteId: {
      get() {
        return parseInt(this.$route.params.site_id);
      },
      set(val) {
        this.$router.replace({
          name: this.$route.name,
          params: {
            customer_id: this.customer.id,
            site_id: val
          }
        });
      }
    },
    sites() {
      return this.customer.sites;
    },
    selectedSite() {
      return this.customer.sites.find(site => site.id === this.siteId);
    },
    projects() {
      return this.selectedSite?.projects;
    },
    services() {
      return this.projects?.map(project => {
        return { value: project.id, text: project.service_type.name };
      });
    },
    serviceTypeName() {
      return this.project?.service_type?.name;
    },
    showItemInfos() {
      let will_show = false
      this.itemInfos.forEach(element => {
        if (element.is_checkbox) {
          if (this.project[element.keys].length > 0) {
            will_show = true;
          }
        } else if (this.detailInfo[element.keys][this.project[element.keys]]) {
          will_show = true;
        }
      });

      return will_show
    },

    customerAddress() {
      if (
        !this.customer.prefecture?.name &&
        !this.customer.city &&
        !this.customer.address
      )
        return "";
      return `
      ${this.customer.prefecture?.name || ""}
      ${this.customer.city || ""}
      ${this.customer.address || ""}`;
    },

    siteAddress() {
      if (
        !this.selectedSite.prefecture?.name &&
        !this.selectedSite.city &&
        !this.selectedSite.address
      )
        return "-";
      return `
      ${this.selectedSite.prefecture?.name || ""}
      ${this.selectedSite.city || ""}
      ${this.selectedSite.address || ""}`;
    }
  },
  methods: {
    showText(array) {
      if (array && Array.isArray(array)) {
        let text = array
          .map(c => {
            if (c) {
              if (c.extra_type == 'with_text') {
                return (
                  c.text + ' (' + (c.text_value ? c.text_value : c.value) + ')'
                )
              }
              return c.text ? c.text : c
            } else {
              return ''
            }
          })
          .join(', ')

        if (text) {
          return text
        } else {
          return '-'
        }

      }
    },
    goToAddress(address) {
      if (address != '-') {
        window.open(`https://www.google.com/maps?q=${address}`, '_blank')
      }
    },
    openFilePDF() {
      window.open(this.selectedSite.file_pdf_url, '_blank');
    },
    limitStr(string, limit) {
      let str = string;

      if (typeof str === 'string' && str.length > limit) {
        str = str.slice(0, limit) + '...';
      }

      return str;
    },
  },
  created() {
    if (this.$route.params.site_id) {
      let site_selected = this.sites.find((site) => site.id == this.$route.params.site_id)
      this.$emit("update:site", site_selected.value)
    }
  },
  data() {
    return {
      itemInfos: [
        { title: "駐車場", keys: "parking" },
        { title: "ブレーカー", keys: "lightening_braker" },
        { title: "鍵", keys: "key" },
        { title: "電源", keys: "outlet" },
        { title: "新築/中古", keys: "site_age" },
        { title: "異常", keys: "electrical_issues" },
        { title: "入居者", keys: "residents" },
        { title: "シャッター", keys: "shutter" },
        { title: "家具類", keys: "furnitures" },
        { title: "カーテン", keys: "curtain" },
        { title: "エアコン", keys: "air_conditioner" }
      ],
      detailInfo: {
        parking: ["敷地内", "コインパーキング", "近隣"],
        lightening_braker: ["入", "切"],
        key: ["立ち合い", "キーバンカー", "その他対応"],
        electrical_issues: ["あり", "なし"],
        site_age: ["中古", "新規"],
        outlet: ["本設", "仮設"],
        residents: ["あり", "なし"],
        shutter: ["あり", "なし"],
        furnitures: ["あり", "なし"],
        curtain: ["あり", "なし"],
        air_conditioner: ["あり", "使用", "不使用", "不明", "なし"]
      },
      on_site_monitoring: ['あり', 'なし']
    };
  }
};
</script>

<style lang="scss" scoped>
.customer-box {
  background: #fffff0;
  border: 1px solid #cfd180;
  box-sizing: border-box;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  .form {
    &-header {
      color: #393a00;
      font-size: 16px;
    }

    &-text {
      background: #ffffff;
      //border: 2px solid #4F55A7;
      box-sizing: border-box;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
    }
  }

  &-form {
    &-input {
      ::v-deep {
        fieldset {
          border: 3px solid #4f55a7;
          box-sizing: border-box;
          box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
          border-radius: 5px;
          background: white !important;
        }
      }
    }
  }

  &-detail {
    &-headline {
      color: #757900;
      font-size: 16px;
    }

    &-content {
      color: #333333;
      word-break: break-all;

      &.general-guideline {
        line-height: 1.5;
      }
    }
  }
}

.customer {
  &-name {
    &-top {
      font-weight: 700;
      font-size: 16px;
      color: #3a3c00;
    }

    &-bottom {
      small {
        font-size: 13px;
        display: block;
      }

      font-weight: 700;
      font-size: 20px;
      color: #333333;
    }
  }
}

.cm-add-btn {
  border-radius: 8px !important;
  color: #4F55A7;
  font-weight: 700;
  border: 1px dashed #4F55A7;
}
.white-space-pre {
  white-space: pre-line;
  word-break: break-all;
}
</style>
